<template>
  <div>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-card tile outlined>
          <v-layout wrap justify-center>
            <v-flex xs12 pa-2>
              <v-layout wrap justify-end>
                <v-flex xs12 sm2 md1 xl2>
                  <v-avatar color="#FFF9C4" size="70">
                    <v-img v-if="storage.photo" :src="mediaURL + storage.photo">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="#FDCE48"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-avatar>
                </v-flex>
                <v-flex xs12 sm10 md10 xl10 align-self-center>
                  <v-layout wrap justify-start>
                    <v-flex xs6 sm3 md2 xl4>
                      <v-layout wrap justify-center>
                        <v-flex xs12 text-left>
                          <span class="itemKey">Event Type</span>
                        </v-flex>
                        <v-flex xs12 text-left>
                          <span class="itemValue">
                            {{ storage.name }}
                            <span v-if="!storage.name">-</span>
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs6 sm3 md2 xl4>
                      <v-layout wrap justify-center>
                        <v-flex xs12 text-left>
                          <span class="itemKey">Hive Id</span>
                        </v-flex>
                        <v-flex xs12 text-left>
                          <span class="itemValue" v-if="storage.colony">
                            {{ storage.colony.colonyidentifier }}
                            <span v-if="!storage.colony.colonyidentifier"
                              >-</span
                            >
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs6 sm3 md2 xl4>
                      <v-layout wrap justify-center>
                        <v-flex xs12 text-left>
                          <span class="itemKey">Hive Status</span>
                        </v-flex>
                        <v-flex xs12 text-left>
                          <span class="itemValue" v-if="storage.colony">
                            {{ storage.colony.colonystatus }}
                            <span v-if="!storage.colony.colonystatus">-</span>
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs6 sm3 md2 xl4>
                      <v-layout wrap justify-center>
                        <v-flex xs12 text-left>
                          <span class="itemKey">Source</span>
                        </v-flex>
                        <v-flex xs12 text-left>
                          <span class="itemValue" v-if="storage.colony">
                            {{ storage.colony.source }}
                            <span v-if="!storage.colony.source">-</span>
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs6 sm3 md2 xl4>
                      <v-layout wrap justify-center>
                        <v-flex xs12 text-left>
                          <span class="itemKey">Event Date</span>
                        </v-flex>
                        <v-flex xs12 text-left>
                          <span class="itemValue">
                            {{ formatDate(storage.eventdate) }}
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs6 sm2 md1 xl2 text-right align-self-center>
                  <v-btn
                    block
                    tile
                    small
                    depressed
                    color="#1885f2"
                    :ripple="false"
                    :to="'/Event/' + storage._id"
                    class="itemValue"
                  >
                    <span style="color: #ffffff"> View </span>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props: ["storage"],
  data() {
    return {};
  },
  methods: {
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toString();
      day = day < 10 ? "0" + day : day;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      return strTime;
    },
  },
};
</script>