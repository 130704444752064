<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#FDCE48"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#FDCE48" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #000">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12 text-left>
        <span class="itemHeading"> Events List </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-end pt-4>
      <v-flex xs12 sm4 md3 xl3 text-left pr-md-1>
        <v-autocomplete
          outlined
          dense
          clearable
          color="#000000"
          label="Hive ID"
          v-model="hive"
          :items="hives"
          item-color="#000000"
          item-text="colonyidentifier"
          item-value="_id"
          hide-details
        >
          <template v-slot:selection="{ item }">
            <span style="font-size: 12px">
              {{ item.colonyidentifier }} ({{ item.eventcount.length }} events)
            </span>
          </template>
          <template v-slot:item="{ item }">
            <span class="text-left">
              {{ item.colonyidentifier }} ({{ item.eventcount.length }} events)
            </span>
          </template>
        </v-autocomplete>
      </v-flex>
      <v-flex xs12 sm4 md3 xl2 text-left pt-2 pt-sm-0 pr-md-1>
        <v-autocomplete
          outlined
          dense
          clearable
          color="#000000"
          label="Event Type"
          v-model="event"
          :items="events"
          item-color="#000000"
          item-text="name"
          item-value="value"
          hide-details
        >
          <template v-slot:selection="{ item }">
            {{ item.name }}
          </template>
          <template v-slot:item="{ item }">
            <span class="text-left">
              {{ item.name }}
            </span>
          </template>
        </v-autocomplete>
      </v-flex>
      <v-flex xs12 sm4 md2 xl2 text-left pt-2 pt-sm-0 px-md-1>
        <v-menu
          ref="fromDatePicker"
          v-model="fromDatePicker"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              dense
              clearable
              v-model="fromDate"
              label="From Date"
              v-bind="attrs"
              v-on="on"
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="fromDate"
            no-title
            @input="fromDatePicker = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex xs12 sm4 md2 xl2 text-left pt-2 pt-sm-0 pl-md-1>
        <v-menu
          ref="toDatePicker"
          v-model="toDatePicker"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              dense
              clearable
              v-model="toDate"
              label="To Date"
              v-bind="attrs"
              v-on="on"
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="toDate"
            no-title
            @input="toDatePicker = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start py-4>
      <v-flex xs12 lg12 xl6 pa-1 v-for="(item, i) in events" :key="i">
        <EventsItem v-bind:storage="item" />
      </v-flex>
      <v-flex xs12 v-if="events.length < 1">
        <span class="itemKey"> No data available </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center v-if="pages > 1">
      <v-flex xs12>
        <v-pagination
          v-model="currentPage"
          :length="pages"
          :total-visible="7"
          color="#919191"
        ></v-pagination>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import EventsItem from "./eventsItem";
export default {
  components: {
    EventsItem,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      hives: [],
      pages: 0,
      count: 20,
      events: [
        { name: "Examination", value: "Examination" },
        { name: "Diseases / Treatments / Pests", value: "Diseases" },
        { name: "Honey Harvesting", value: "Honey Collection" },
        { name: "Feeding", value: "Feeding" },
        { name: "Queen Replacement", value: "Queen Replacement" },
        { name: "Reset Colony", value: "Reset Colony" },
        { name: "Division", value: "Division" },
      ],
      fromDatePicker: false,
      toDatePicker: false,
      event: this.$route.query.event,
      hive: this.$route.query.hive,
      fromDate: this.$route.query.from,
      toDate: this.$route.query.to,
      currentPage: this.$route.query.page ? Number(this.$route.query.page) : 1,
    };
  },
  watch: {
    currentPage() {
      this.$router.push({
        path: "/Events",
        query: {
          event: this.event,
          hive: this.hive,
          from: this.fromDate,
          to: this.toDate,
          page: this.currentPage,
        },
      });
    },
    event() {
      this.changePage();
    },
    hive() {
      this.changePage();
    },
    fromDate() {
      this.changePage();
    },
    toDate() {
      this.changePage();
    },
  },
  beforeMount() {
    this.getData();
    this.getHives();
  },
  methods: {
    changePage() {
      this.$router.push({
        path: "/Events",
        query: {
          event: this.event,
          hive: this.hive,
          from: this.fromDate,
          to: this.toDate,
        },
      });
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/event/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          colony: this.hive,
          event: this.event,
          from: this.fromDate,
          to: this.toDate,
          page: this.page,
          count: this.count,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.events = response.data.data;
            this.pages = response.data.pages;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getHives() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/colony/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.hives = response.data.data;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>